import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getProvinces = createAsyncThunk("provinces", async () => {
  const response = await apiClient.get("provinces");
  return response.data;
});

export const getProvinceById = createAsyncThunk("provinces/id", async ( id ) => {
  const response = await apiClient.get(`provinces/${id}`);
  return response.data;
});

export const deleteProvinceById = createAsyncThunk("provinces/delete", async ( id ) => {
  const response = await apiClient.delete(`provinces/${id}`);
  return response.data;
});

export const addProvince = createAsyncThunk("provinces/add", async (province) => {
delete province.deleted_at;
delete province.updated_at;
delete province.created_at;
delete province.is_active;
delete province.modules;
  const response = await apiClient.post("provinces", province);
  return response.data;
});

export const updateProvince = createAsyncThunk("provinces/update", async ( province ) => {
  delete province.deleted_at;
  delete province.updated_at;
  delete province.created_at;
  delete province.is_active;
  delete province.modules;
  
  const response = await apiClient.put(`provinces/${province.uuid}`, province);
  return response.data;
});