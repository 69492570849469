import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getBranches = createAsyncThunk("branches", async () => {
  const response = await apiClient.get("branches");
  return response.data;
});

export const getBranchById = createAsyncThunk("branches/id", async ( id ) => {
  const response = await apiClient.get(`branches/${id}`);
  return response.data;
});

export const deleteBranchById = createAsyncThunk("branches/delete", async ( id ) => {
  const response = await apiClient.delete(`branches/${id}`);
  return response.data;
});

export const addBranch = createAsyncThunk("branches/add", async (branch) => {
  const response = await apiClient.post("branches", branch);
  return response.data;
});