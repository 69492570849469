import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getPlans = createAsyncThunk("plans", async () => {
  const response = await apiClient.get("plans");
  return response.data;
});

export const getPlanById = createAsyncThunk("plans/id", async ( id ) => {
  const response = await apiClient.get(`plans/${id}`);
  return response.data;
});

export const deletePlanById = createAsyncThunk("plans/delete", async ( id ) => {
  const response = await apiClient.delete(`plans/${id}`);
  return response.data;
});

export const addPlan = createAsyncThunk("plans/add", async (plan) => {
  const response = await apiClient.post("plans", plan);
  return response.data;
});

export const updatePlanById = createAsyncThunk(
  "plans/id",
  async ({ id, data }) => {
    const response = await apiClient.put(`plans/${id}`, data);
    return response.data;
  }
);
