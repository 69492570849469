import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Box,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import {
  FormBoxContainer,
  FormDialogSubtitle,
  FormDialogTitle,
  SeparatorHrBox,
  SelectFormControl,
  FormBoxLeft,
  FormBoxRight,
  FormDivider,
} from "../../assets/styles/tables";
import { useDispatch, useSelector } from "react-redux";
import Actions from "./Actions";

import originalAdditionals from "../../data/tableContentAdditionals";
import originalScoreCoefficents from "../../data/tableContentScoreCoefficents";
import originalPreAdjRs from "../../data/tableContentPreAdjudicationReasons";
import PlanTab from "../tab/PlanTab";

import { setSelectedFee } from "../../features/fees/feeSlice";

//endpoints
import {
  getGroups,
  deleteGroupById,
  addGroup,
  updateGroupById,
} from "../../api/endpoints/groups";
import {
  getAdditionals,
  deleteAdditionalById,
  addAdditional,
  updateAdditionalById,
} from "../../api/endpoints/additionals";
import {
  getScoreCoefficents,
  deleteScoreCoefficentById,
  addScoreCoefficent,
  updateScoreCoefficentById,
} from "../../api/endpoints/scoreCoefficents";
import {
  getPreAdjudicationReasons,
  deletePreAdjudicationReasonById,
  addPreAdjudicationReason,
  updatePreAdjudicationReasonById,
} from "../../api/endpoints/preAdjudicationReasons";
import {
  getConstTypes,
  deleteConstTypeById,
  addConstType,
  updateConstTypeById,
} from "../../api/endpoints/constructionTypes";
import {
  getLegalEntities,
  deleteLegalEntitieById,
  addLegalEntitie,
  updateLegalEntitieById,
} from "../../api/endpoints/legalEntities";
import {
  getFees,
  deleteFeeById,
  addFee,
  updateFeeById,
} from "../../api/endpoints/fees";
import {
  getFeeStructures,
  deleteFeeStructureById,
  addFeeStructure,
  updateFeeStructureById,
} from "../../api/endpoints/feeStructures";
import {
  getPaymentMethods,
  deletePaymentMethodById,
  addPaymentMethod,
  updatePaymentMethodById,
} from "../../api/endpoints/paymentMethods";
import {
  getPaymentMethodDetails,
  deletePaymentMethodDetailById,
  addPaymentMethodDetail,
  updatePaymentMethodDetailById,
} from "../../api/endpoints/paymentMethodDetails";

const PlanDialog = (props) => {
  const dispatch = useDispatch();
  const {
    open,
    handleClose,
    editMode,
    selectedPlan,
    handleInputChange,
    handleSave,
    namePlan,
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const actionProps = { handleClose, handleSave };
  const [activeTab, setActiveTab] = useState(0);
  const ITEMS_PER_PAGE = 5;

  //#region GRUPOS
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [pageGroup, setPageGroup] = useState(1);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [nameGroup, setNameGroup] = useState("");
  const [editModeGroup, setEditModeGroup] = useState(false);
  const [openGroup, setOpenGroup] = useState(false);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const fetchGroupes = async () => {
      setIsLoading(true);
      try {
        const groupesData = await dispatch(getGroups()).unwrap();

        const groupesWithId = groupesData.map((user, index) => ({
          ...user,
          id: index,
        }));

        const filteredGroupes = groupesWithId.filter(
          (group) => group.uuid_plan === selectedPlan.uuid
        );

        setGroups(filteredGroupes);
        setFilteredGroups(filteredGroupes);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchGroupes();
  }, [selectedPlan]);

  const getCurrentGroupPageItems = () => {
    const startIndex = (pageGroup - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return filteredGroups.slice(startIndex, endIndex);
  };

  const handleEditGroup = (group) => {
    setSelectedGroup(group);
    setEditModeGroup(true);
    setOpenGroup(true);
    setNameGroup(group.name);
  };
  const handleDeleteGroup = (id, uuid) => {
    const updatedGroups = groups.filter((group) => group.id !== id);
    dispatch(deleteGroupById(uuid)).unwrap();
    setFilteredGroups(updatedGroups);
    setGroups(updatedGroups);
  };
  const handleCloseGroup = () => {
    setOpenGroup(false);
    setEditModeGroup(false);
  };

  const handleInputChangeGroup = (event) => {
    const { name, value } = event.target;
    setSelectedGroup({ ...selectedGroup, [name]: value });
  };

  const handleSelectChangeGroup = (event) => {
    const { name, value } = event.target;
    setSelectedGroup({ ...selectedGroup, [name]: value });
  };

  const handleSaveGroup = async () => {
    if (editModeGroup) {
      const updatedGroups = groups.map((group) =>
        group.id === selectedGroup.id ? selectedGroup : group
      );
      const updateGroup = {
        id: selectedGroup.uuid,
        data: {
          name: selectedGroup.name,
          number_from: selectedGroup.number_from,
          number_to: selectedGroup.number_to,
          subgroup_of: selectedGroup.subgroup_of,
        },
      };
      const updatedGroup = await dispatch(
        updateGroupById(updateGroup)
      ).unwrap();
      setFilteredGroups(updatedGroups);
      handleCloseGroup();
    } else {
      try {
        const newGroup = {
          uuid_plan: selectedPlan.uuid,
          name: selectedGroup.name,
          number_from: selectedGroup.number_from,
          number_to: selectedGroup.number_to,
          subgroup_of: selectedGroup.subgroup_of,
        };
        const addedPlan = await dispatch(addGroup(newGroup)).unwrap();
        setSelectedGroup({
          ...addedPlan,
          id: groups.length + 1,
        });
        setFilteredGroups([...groups, addedPlan]);
        setGroups([...groups, addedPlan]);
        handleCloseGroup();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const groupDialogProps = {
    openGroup,
    handleCloseGroup,
    editModeGroup,
    selectedGroup,

    handleInputChangeGroup,
    handleSelectChangeGroup,
    handleSaveGroup,

    nameGroup,
  };
  //#endregion

  //#region ADICIONALES
  const [filteredAdditionals, setFilteredAdditionals] = useState([]);
  const [pageAdditional, setPageAdditional] = useState(1);
  const [selectedAdditional, setSelectedAdditional] = useState({});
  const [nameAdditional, setNameAdditional] = useState("");
  const [editModeAdditional, setEditModeAdditional] = useState(false);
  const [openAdditional, setOpenAdditional] = useState(false);
  const [additionals, setAdditionals] = useState([]);

  useEffect(() => {
    const fetchAdditionals = async () => {
      setIsLoading(true);
      try {
        const additionalsData = await dispatch(getAdditionals()).unwrap();

        const additionalsWithId = additionalsData.map((addit, index) => ({
          ...addit,
          id: index,
        }));

        const filteredAdditionals = additionalsWithId.filter(
          (addit) => addit.uuid_plan === selectedPlan.uuid
        );

        setAdditionals(filteredAdditionals);
        setFilteredAdditionals(filteredAdditionals);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchAdditionals();
  }, [selectedPlan]);

  const getCurrentAdditionalPageItems = () => {
    const startIndex = (pageAdditional - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return filteredAdditionals.slice(startIndex, endIndex);
  };

  const handleEditAdditional = (additional) => {
    setSelectedAdditional(additional);
    setEditModeAdditional(true);
    setOpenAdditional(true);
    setNameAdditional(additional.name);
  };

  const handleDeleteAdditional = async (id, uuid) => {
    const updatedAdditionals = additionals.filter((addit) => addit.id !== id);
    await dispatch(deleteAdditionalById(uuid)).unwrap();
    setFilteredAdditionals(updatedAdditionals);
    setAdditionals(updatedAdditionals);
  };

  const handleCloseAdditional = () => {
    setOpenAdditional(false);
    setEditModeAdditional(false);
  };

  const handleInputChangeAdditional = (event) => {
    const { name, value } = event.target;
    setSelectedAdditional({ ...selectedAdditional, [name]: value });
  };

  const handleSelectChangeAdditional = (event) => {
    const { name, value } = event.target;
    setSelectedAdditional({ ...selectedAdditional, [name]: value });
  };

  const handleSaveAdditional = async () => {
    if (editModeAdditional) {
      const updatedAdditionals = additionals.map((add) =>
        add.id === selectedAdditional.id ? selectedAdditional : add
      );
      const updateAdditional = {
        id: selectedAdditional.uuid,
        data: {
          name: selectedAdditional.name,
          amount: selectedAdditional.amount,
          prorate: selectedAdditional.prorate,
          mandatory: selectedAdditional.mandatory,
          // deducted: selectedAdditional.deducted,
        },
      };
      const updatedAdditional = await dispatch(
        updateAdditionalById(updateAdditional)
      ).unwrap();
      setFilteredAdditionals(updatedAdditionals);
    } else {
      try {
        const newAdditional = {
          uuid_plan: selectedPlan.uuid,
          name: selectedAdditional.name,
          amount: selectedAdditional.amount,
          prorate: selectedAdditional.prorate,
          mandatory: selectedAdditional.mandatory,
          // deducted: selectedAdditional.deducted,
        };
        const addedAdditional = await dispatch(
          addAdditional(newAdditional)
        ).unwrap();
        setSelectedAdditional({
          ...addedAdditional,
          id: additionals.length + 1,
        });
        setFilteredAdditionals([...additionals, addedAdditional]);
        setAdditionals([...additionals, addedAdditional]);
        handleCloseAdditional();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const additionalDialogProps = {
    openAdditional,
    handleCloseAdditional,
    editModeAdditional,
    selectedAdditional,
    handleInputChangeAdditional,
    handleSelectChangeAdditional,
    handleSaveAdditional,

    nameAdditional,
  };
  //#endregion

  //#region COEF DE PUNTAJES
  const [filteredScoreCoefficents, setFilteredScoreCoefficents] = useState([]);
  const [pageScoreCoefficent, setPageScoreCoefficent] = useState(1);
  const [selectedScoreCoefficent, setSelectedScoreCoefficent] = useState({});
  const [nameScoreCoefficent, setNameScoreCoefficent] = useState("");
  const [editModeScoreCoefficent, setEditModeScoreCoefficent] = useState(false);
  const [openScoreCoefficent, setOpenScoreCoefficent] = useState(false);
  const [scoreCoefficents, setScoreCoefficents] = useState([]);

  useEffect(() => {
    const fetchScoreCoefficents = async () => {
      setIsLoading(true);
      try {
        const scoreCoefficentsData = await dispatch(
          getScoreCoefficents()
        ).unwrap();

        const scoreCoefficentsWithId = scoreCoefficentsData.map(
          (item, index) => ({
            ...item,
            id: index,
            start_date: (item.start_date || "").slice(0, 10),
            due_date: (item.due_date || "").slice(0, 10),
          })
        );

        const filteredScoreCoefficents = scoreCoefficentsWithId.filter(
          (item) => item.uuid_plan === selectedPlan.uuid
        );

        setScoreCoefficents(filteredScoreCoefficents);
        setFilteredScoreCoefficents(filteredScoreCoefficents);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchScoreCoefficents();
  }, [selectedScoreCoefficent, selectedPlan]);

  const getCurrentScoreCoefficentPageItems = () => {
    const startIndex = (pageScoreCoefficent - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return filteredScoreCoefficents.slice(startIndex, endIndex);
  };

  const handleEditScoreCoefficent = (scoreCoefficent) => {
    setSelectedScoreCoefficent(scoreCoefficent);
    setEditModeScoreCoefficent(true);
    setOpenScoreCoefficent(true);
    setNameScoreCoefficent(scoreCoefficent.name);
  };

  const handleDeleteScoreCoefficent = async (id, uuid) => {
    const updatedScoreCoefficents = scoreCoefficents.filter(
      (sc) => sc.id !== id
    );
    await dispatch(deleteScoreCoefficentById(uuid)).unwrap();
    setFilteredScoreCoefficents(updatedScoreCoefficents);
    setScoreCoefficents(updatedScoreCoefficents);
  };

  const handleCloseScoreCoefficent = () => {
    setOpenScoreCoefficent(false);
    setEditModeScoreCoefficent(false);
  };

  const handleInputChangeScoreCoefficent = (event) => {
    const { name, value } = event.target;
    setSelectedScoreCoefficent({ ...selectedScoreCoefficent, [name]: value });
  };

  const handleSaveScoreCoefficent = async () => {
    if (editModeScoreCoefficent) {
      const updatedScoreCoefficents = scoreCoefficents.map((sc) =>
        sc.id === selectedScoreCoefficent.id ? selectedScoreCoefficent : sc
      );
      const updateScoreCoefficent = {
        id: selectedScoreCoefficent.uuid,
        data: {
          value: selectedScoreCoefficent.value,
          start_date: selectedScoreCoefficent.start_date,
          due_date: selectedScoreCoefficent.due_date,
          // installments_number:1,
          // contracts_number:2
        },
      };
      await dispatch(updateScoreCoefficentById(updateScoreCoefficent)).unwrap();
      setFilteredScoreCoefficents(updatedScoreCoefficents);
    } else {
      try {
        const newScoreCoefficent = {
          uuid_plan: selectedPlan.uuid,
          value: selectedScoreCoefficent.value,
          start_date: selectedScoreCoefficent.start_date,
          due_date: selectedScoreCoefficent.due_date,
          // installments_number:1,
          // contracts_number:2
        };
        const addedScoreCoefficent = await dispatch(
          addScoreCoefficent(newScoreCoefficent)
        ).unwrap();
        setSelectedScoreCoefficent({
          ...addedScoreCoefficent,
          id: scoreCoefficents.length + 1,
        });
        setFilteredScoreCoefficents([
          ...scoreCoefficents,
          addedScoreCoefficent,
        ]);
        setScoreCoefficents([...scoreCoefficents, addedScoreCoefficent]);
      } catch (error) {
        console.error(error);
      }
    }
    handleCloseScoreCoefficent();
  };

  const scoreCoefficentDialogProps = {
    openScoreCoefficent,
    handleCloseScoreCoefficent,
    editModeScoreCoefficent,
    selectedScoreCoefficent,
    handleInputChangeScoreCoefficent,
    handleSaveScoreCoefficent,
    nameScoreCoefficent,
  };
  //#endregion

  //#region MOTIVOS PREADJ.
  const [filteredPreAdjRs, setFilteredPreAdjRs] = useState([]);
  const [pagePreAdjR, setPagePreAdjR] = useState(1);
  const [selectedPreAdjR, setSelectedPreAdjR] = useState({});
  const [namePreAdjR, setNamePreAdjR] = useState("");
  const [editModePreAdjR, setEditModePreAdjR] = useState(false);
  const [openPreAdjR, setOpenPreAdjR] = useState(false);
  const [preAdjRs, setPreAdjRs] = useState([]);

  useEffect(() => {
    const fetchPreAdjRs = async () => {
      setIsLoading(true);
      try {
        const preAdjRsData = await dispatch(
          getPreAdjudicationReasons()
        ).unwrap();

        const preAdjRsWithId = preAdjRsData.map((add, index) => ({
          ...add,
          id: index,
        }));

        const filteredPreAdjRs = preAdjRsWithId.filter(
          (add) => add.uuid_plan === selectedPlan.uuid
        );

        setPreAdjRs(filteredPreAdjRs);
        setFilteredPreAdjRs(filteredPreAdjRs);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchPreAdjRs();
  }, [selectedPlan]);

  const getCurrentPreAdjRPageItems = () => {
    const startIndex = (pagePreAdjR - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return filteredPreAdjRs.slice(startIndex, endIndex);
  };

  const handleEditPreAdjR = (preAdjR) => {
    setSelectedPreAdjR(preAdjR);
    setEditModePreAdjR(true);
    setOpenPreAdjR(true);
    setNamePreAdjR(preAdjR.name);
  };

  const handleDeletePreAdjR = async (id, uuid) => {
    const updatedPreAdjRs = preAdjRs.filter((par) => par.id !== id);
    await dispatch(deletePreAdjudicationReasonById(uuid)).unwrap();
    setFilteredPreAdjRs(updatedPreAdjRs);
    setPreAdjRs(updatedPreAdjRs);
  };
  const handleClosePreAdjR = () => {
    setOpenPreAdjR(false);
    setEditModePreAdjR(false);
  };

  const handleInputChangePreAdjR = (event) => {
    const { name, value } = event.target;
    setSelectedPreAdjR({ ...selectedPreAdjR, [name]: value });
  };

  const handleSavePreAdjR = async () => {
    if (editModePreAdjR) {
      const updatedPreAdjRs = preAdjRs.map((par) =>
        par.id === selectedPreAdjR.id ? selectedPreAdjR : par
      );
      const updatePreAdjR = {
        id: selectedPreAdjR.uuid,
        data: {
          name: selectedPreAdjR.name,
          description: selectedPreAdjR.description,
        },
      };
      const updatedPreAdjR = await dispatch(
        updatePreAdjudicationReasonById(updatePreAdjR)
      ).unwrap();
      setFilteredPreAdjRs(updatedPreAdjRs);
    } else {
      try {
        const newPreAdjR = {
          uuid_plan: selectedPlan.uuid,
          name: selectedPreAdjR.name,
          description: selectedPreAdjR.description,
        };
        const addedPreAdjR = await dispatch(
          addPreAdjudicationReason(newPreAdjR)
        ).unwrap();
        setSelectedPreAdjR({
          ...addedPreAdjR,
          id: preAdjRs.length + 1,
        });
        setFilteredPreAdjRs([...preAdjRs, addedPreAdjR]);
        setPreAdjRs([...preAdjRs, addedPreAdjR]);
        handleClosePreAdjR();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const preAdjRDialogProps = {
    openPreAdjR,
    handleClosePreAdjR,
    editModePreAdjR,
    selectedPreAdjR,

    handleInputChangePreAdjR,
    handleSavePreAdjR,

    namePreAdjR,
  };
  //#endregion

  //#region TIPOS DE CONSTRUCCION
  const [filteredConstTypes, setFilteredConstTypes] = useState([]);
  const [pageConstType, setPageConstType] = useState(1);
  const [selectedConstType, setSelectedConstType] = useState({});
  const [nameConstType, setNameConstType] = useState("");
  const [editModeConstType, setEditModeConstType] = useState(false);
  const [openConstType, setOpenConstType] = useState(false);
  const [constTypes, setConstTypes] = useState([]);

  useEffect(() => {
    const fetchConstTypes = async () => {
      setIsLoading(true);
      try {
        const constTypesData = await dispatch(getConstTypes()).unwrap();

        const constTypesWithId = constTypesData.map((type, index) => ({
          ...type,
          id: index,
        }));

        const filteredConstTypes = constTypesWithId.filter(
          (type) => type.uuid_plan === selectedPlan.uuid
        );

        setConstTypes(filteredConstTypes);
        setFilteredConstTypes(filteredConstTypes);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchConstTypes();
  }, [selectedPlan]);

  const getCurrentConstTypePageItems = () => {
    const startIndex = (pageConstType - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return filteredConstTypes.slice(startIndex, endIndex);
  };

  const handleEditConstType = (type) => {
    setSelectedConstType(type);
    setEditModeConstType(true);
    setOpenConstType(true);
    setNameConstType(type.name);
  };

  const handleDeleteConstType = (id, uuid) => {
    const updatedConstTypes = constTypes.filter((type) => type.id !== id);
    dispatch(deleteConstTypeById(uuid)).unwrap();
    setFilteredConstTypes(updatedConstTypes);
    setConstTypes(updatedConstTypes);
  };

  const handleCloseConstType = () => {
    setOpenConstType(false);
    setEditModeConstType(false);
  };

  const handleInputChangeConstType = (event) => {
    const { name, value } = event.target;
    setSelectedConstType({ ...selectedConstType, [name]: value });
  };

  const handleSelectChangeConstType = (event) => {
    const { name, value } = event.target;
    setSelectedConstType({ ...selectedConstType, [name]: value });
  };

  const handleSaveConstType = async () => {
    if (editModeConstType) {
      const updatedConstTypes = constTypes.map((type) =>
        type.id === selectedConstType.id ? selectedConstType : type
      );
      const updateConstType = {
        id: selectedConstType.uuid,
        data: {
          name: selectedConstType.name,
          surface: selectedConstType.surface,
          description: selectedConstType.description,
          // image_url: selectedConstType.image_url,
        },
      };
      const updatedConstType = await dispatch(
        updateConstTypeById(updateConstType)
      ).unwrap();
      setFilteredConstTypes(updatedConstTypes);
      handleCloseConstType();
    } else {
      try {
        const newConstType = {
          uuid_plan: selectedPlan.uuid,
          name: selectedConstType.name,
          surface: selectedConstType.surface,
          description: selectedConstType.description,
          // image_url: selectedConstType.image_url,
        };
        const addedPlan = await dispatch(addConstType(newConstType)).unwrap();
        setSelectedConstType({
          ...addedPlan,
          id: constTypes.length + 1,
        });
        setFilteredConstTypes([...constTypes, addedPlan]);
        setConstTypes([...constTypes, addedPlan]);
        handleCloseConstType();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const constTypeDialogProps = {
    openConstType,
    handleCloseConstType,
    editModeConstType,
    selectedConstType,

    handleInputChangeConstType,
    handleSelectChangeConstType,
    handleSaveConstType,

    nameConstType,
  };
  //#endregion

  //#region VALORES PEI
  const [pagePeiValue, setPagePeiValue] = useState(1);
  const [editModePeiValue, setEditModePeiValue] = useState(false);
  const [openPeiValue, setOpenPeiValue] = useState(false);
  const [openPeiValueEdit, setOpenPeiValueEdit] = useState(false);
  const [editModePeiValueEdit, setEditModePeiValueEdit] = useState(false);
  const [legalEntities, setLegalEntities] = useState([]);

  useEffect(() => {
    const fetchLegalEntities = async () => {
      setIsLoading(true);
      try {
        const legalEntitiesData = await dispatch(getLegalEntities()).unwrap();
        const legalEntitiesWithId = legalEntitiesData.map((value, index) => ({
          ...value,
          id: index,
        }));
        
        const filteredIntermediateEntities = legalEntitiesWithId.filter(
          (value) => value.is_intermediate_entity == "1"
        );
        
        setLegalEntities(filteredIntermediateEntities);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchLegalEntities();
  }, [selectedPlan]);

  const handleClosePeiValue = () => {
    setOpenPeiValue(false);
    setEditModePeiValue(false);
  };

  const peiValueDialogProps = {
    openPeiValueEdit,
    openPeiValue,
    handleClosePeiValue,
    setOpenPeiValueEdit,
    setEditModePeiValueEdit,
    editModePeiValue,
    selectedPlan,
  };
  //#endregion

  //#region TIPOS DE CUOTAS

  const { feeTypes } = useSelector((state) => state.feeTypes);
  const [pageFee, setPageFee] = useState(1);
  const [selectedFee, setSelectedFee] = useState({});
  const [nameFee, setNameFee] = useState("");
  const [editModeFee, setEditModeFee] = useState(false);
  const [openFee, setOpenFee] = useState(false);
  const [fees, setFees] = useState([]);

  useEffect(() => {
    const fetchFees = async () => {
      setIsLoading(true);
      try {
        const feesData = await dispatch(getFees()).unwrap();

        const feesWithIdAndType = feesData.map((fee, index) => {
          const feeType = feeTypes.find(
            (type) => type.uuid === fee.uuid_fee_type
          );
          return {
            ...fee,
            id: index,
            feeType: feeType ? feeType.name : null,
          };
        });

        const filteredFees = feesWithIdAndType.filter(
          (type) => type.uuid_plan === selectedPlan.uuid
        );

        setFees(filteredFees);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchFees();
  }, [selectedPlan]);

  const handleEditFee = (fee) => {
    setSelectedFee(fee);
    setEditModeFee(true);
    setOpenFee(true);
    setNameFee(fee.name);
  };

  const handleDeleteFee = (id, uuid) => {
    const updatedFees = fees.filter((type) => type.id !== id);
    dispatch(deleteFeeById(uuid)).unwrap();
    setFees(updatedFees);
  };

  const handleCloseFee = () => {
    setOpenFee(false);
    setEditModeFee(false);
  };

  const handleInputChangeFee = (event) => {
    const { name, value } = event.target;
    setSelectedFee({ ...selectedFee, [name]: value });
  };

  const handleSelectChangeFee = (event) => {
    const { name, value } = event.target;
    setSelectedFee({ ...selectedFee, [name]: value });
  };

  const handleSaveFee = async () => {
    const feeType = feeTypes.find((type) => type.name === selectedFee.feeType);

    if (editModeFee) {
      const updatedFees = fees.map((type) =>
        type.id === selectedFee.id ? selectedFee : type
      );
      const updateFee = {
        id: selectedFee.uuid,
        data: {
          name: selectedFee.name,
          uuid_fee_type: feeType.uuid,
          complete: selectedFee.complete,
          score: selectedFee.score,
        },
      };
      const updatedFee = await dispatch(updateFeeById(updateFee)).unwrap();
      setFees(updatedFees);
      handleCloseFee();
    } else {
      try {
        const newFee = {
          uuid_plan: selectedPlan.uuid,
          name: selectedFee.name,
          uuid_fee_type: feeType.uuid,
          complete: selectedFee.complete,
          score: selectedFee.score,
        };
        const addedFee = await dispatch(addFee(newFee)).unwrap();
        setSelectedFee({
          ...selectedFee,
          id: fees.length + 1,
        });
        setFees([...fees, selectedFee]);
        handleCloseFee();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const feeDialogProps = {
    openFee,
    handleCloseFee,
    editModeFee,
    selectedFee,

    handleInputChangeFee,
    handleSelectChangeFee,
    handleSaveFee,

    nameFee,
  };
  //#endregion

  //#region ESTRUCTURA DE CUOTAS

  const feeStructures = useSelector((state) => state.feeStructures.data);
  const { selectedFee: selectedFeeForFilter } = useSelector(
    (state) => state.fees
  );
  const [selectedFeeStructure, setSelectedFeeStructure] = useState({});
  const [nameFeeStructure, setNameFeeStructure] = useState("");
  const [editModeFeeStructure, setEditModeFeeStructure] = useState(false);
  const [openFeeStructure, setOpenFeeStructure] = useState(false);

  useEffect(() => {
    dispatch(getFeeStructures());
  }, [dispatch, selectedPlan]);

  const handleEditFeeStructure = (feeStructure) => {
    setSelectedFeeStructure(feeStructure);
    setEditModeFeeStructure(true);
    setOpenFeeStructure(true);
    setNameFeeStructure(feeStructure.name);
  };

  const handleDeleteFeeStructure = (uuid) => {
    dispatch(deleteFeeStructureById(uuid));
  };

  const handleCloseFeeStructure = () => {
    setOpenFeeStructure(false);
    setEditModeFeeStructure(false);
  };

  const handleInputChangeFeeStructure = (event) => {
    const { name, value } = event.target;
    setSelectedFeeStructure((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChangeFeeStructure = (event) => {
    const { name, value } = event.target;
    setSelectedFeeStructure((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveFeeStructure = async () => {
    const feeStructureData = {
      uuid_plan: selectedPlan.uuid,
      uuid_fee: selectedFeeForFilter.uuid,
      name: selectedFeeStructure.name,
      fixed_amount: selectedFeeStructure.fixed_amount,
      rate: selectedFeeStructure.rate,
      iva: selectedFeeStructure.iva,
    };

    if (editModeFeeStructure) {
      const updateFeeStructure = {
        id: selectedFeeStructure.uuid,
        data: feeStructureData,
      };
      dispatch(updateFeeStructureById(updateFeeStructure));
    } else {
      const addedFeeStructure = await dispatch(
        addFeeStructure(feeStructureData)
      ).unwrap();
      setSelectedFeeStructure((prev) => ({
        ...prev,
        id: feeStructures.length + 1,
        uuid: addedFeeStructure.uuid,
      }));
    }

    handleCloseFeeStructure();
  };

  const feeStructureDialogProps = {
    openFeeStructure,
    handleCloseFeeStructure,
    editModeFeeStructure,
    selectedFeeStructure,

    handleInputChangeFeeStructure,
    handleSelectChangeFeeStructure,
    handleSaveFeeStructure,

    nameFeeStructure,
  };

  //#endregion

  //#region FORMA DE PAGOS
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
  const [namePaymentMethod, setNamePaymentMethod] = useState("");
  const [editModePaymentMethod, setEditModePaymentMethod] = useState(false);
  const [openPaymentMethod, setOpenPaymentMethod] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const { setPaymentMethod } = useSelector((state) => state.paymentMethods);

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      setIsLoading(true);
      try {
        const paymentMethodsData = await dispatch(getPaymentMethods()).unwrap();

        const paymentMethodsWithId = paymentMethodsData.map((type, index) => ({
          ...type,
          id: index,
          low_date: (type.low_date || "").slice(0, 10),
        }));

        const filteredPaymentMethods = paymentMethodsWithId.filter(
          (type) => type.uuid_plan === selectedPlan.uuid
        );

        setPaymentMethods(filteredPaymentMethods);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchPaymentMethods();
  }, [selectedPlan]);

  const handleEditPaymentMethod = (type) => {
    setSelectedPaymentMethod(type);
    setEditModePaymentMethod(true);
    setOpenPaymentMethod(true);
    setNamePaymentMethod(type.name);
  };

  const handleDeletePaymentMethod = (id, uuid) => {
    const updatedPaymentMethods = paymentMethods.filter(
      (type) => type.id !== id
    );
    dispatch(deletePaymentMethodById(uuid)).unwrap();
    setPaymentMethods(updatedPaymentMethods);
  };

  const handleClosePaymentMethod = () => {
    setOpenPaymentMethod(false);
    setEditModePaymentMethod(false);
  };

  const handleInputChangePaymentMethod = (event) => {
    const { name, value } = event.target;
    setSelectedPaymentMethod({ ...selectedPaymentMethod, [name]: value });
  };

  const handleSelectChangePaymentMethod = (event) => {
    const { name, value } = event.target;
    setSelectedPaymentMethod({ ...selectedPaymentMethod, [name]: value });
  };

  const handleSavePaymentMethod = async () => {
    if (editModePaymentMethod) {
      const updatedPaymentMethods = paymentMethods.map((type) =>
        type.id === selectedPaymentMethod.id ? selectedPaymentMethod : type
      );
      const updatePaymentMethod = {
        id: selectedPaymentMethod.uuid,
        data: {
          name: selectedPaymentMethod.name,
          // description:selectedPaymentMethod.description,
          low_date: selectedPaymentMethod.low_date,
          outlined_in_contract: selectedPaymentMethod.outlined_in_contract,
        },
      };
      const updatedPaymentMethod = await dispatch(
        updatePaymentMethodById(updatePaymentMethod)
      ).unwrap();
      setPaymentMethods(updatedPaymentMethods);
      handleClosePaymentMethod();
      //actualizo el objeto en el estado de paymentmethods en redux
      setPaymentMethod({ data: updatePaymentMethod, update: true });
    } else {
      try {
        const newPaymentMethod = {
          uuid_plan: selectedPlan.uuid,
          name: selectedPaymentMethod.name,
          // description:selectedPaymentMethod.description,
          low_date: selectedPaymentMethod.low_date,
          outlined_in_contract: selectedPaymentMethod.outlined_in_contract,
        };
        const addedPaymentMethod = await dispatch(
          addPaymentMethod(newPaymentMethod)
        ).unwrap();
        setSelectedPaymentMethod({
          ...addedPaymentMethod,
          id: paymentMethods.length + 1,
        });
        setPaymentMethods([...paymentMethods, addedPaymentMethod]);
        handleClosePaymentMethod();
        //agrego el objeto a el estado de paymentmethods a redux
        setPaymentMethod({ data: newPaymentMethod, update: false });
        //
      } catch (error) {
        console.error(error);
      }
    }
  };

  const paymentMethodDialogProps = {
    openPaymentMethod,
    handleClosePaymentMethod,
    editModePaymentMethod,
    selectedPaymentMethod,
    handleInputChangePaymentMethod,
    handleSelectChangePaymentMethod,
    handleSavePaymentMethod,
    namePaymentMethod,
  };
  //#endregion

  //#region DETALLE DE FORMA DE PAGOS
  const { fees: feesForPMD, selectedFee: selectedFeeForPMD } = useSelector(
    (state) => state.fees
  );
  const { selectedPaymentMethod: selectedPaymentMethodForPMD } = useSelector(
    (state) => state.paymentMethods
  );
  const [selectedPaymentMethodDetail, setSelectedPaymentMethodDetail] =
    useState({});
  const [namePaymentMethodDetail, setNamePaymentMethodDetail] = useState("");
  const [editModePaymentMethodDetail, setEditModePaymentMethodDetail] =
    useState(false);
  const [openPaymentMethodDetail, setOpenPaymentMethodDetail] = useState(false);
  const [paymentMethodDetails, setPaymentMethodDetails] = useState([]);

  useEffect(() => {
    const fetchPaymentMethodDetails = async () => {
      setIsLoading(true);
      try {
        const paymentMethodDetailsData = await dispatch(
          getPaymentMethodDetails()
        ).unwrap();

        const paymentMethodDetailsWithIdAndFee = paymentMethodDetailsData.map(
          (type, index) => {
            const fee = feesForPMD.find((fee) => fee.uuid === type.uuid_fee);
            return {
              ...type,
              id: index,
              fee: fee,
            };
          }
        );

        const filteredPaymentMethodDetails =
          paymentMethodDetailsWithIdAndFee.filter(
            (type) => type.uuid_plan === selectedPlan.uuid
          );

        setPaymentMethodDetails(filteredPaymentMethodDetails);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchPaymentMethodDetails();
  }, [selectedPlan]);

  const handleEditPaymentMethodDetail = (type) => {
    setSelectedPaymentMethodDetail({ ...type, fee: type.fee });
    setEditModePaymentMethodDetail(true);
    setOpenPaymentMethodDetail(true);
    setNamePaymentMethodDetail(type.name);
  };

  const handleDeletePaymentMethodDetail = (id, uuid) => {
    const updatedPaymentMethodDetails = paymentMethodDetails.filter(
      (type) => type.id !== id
    );
    dispatch(deletePaymentMethodDetailById(uuid)).unwrap();
    setPaymentMethodDetails(updatedPaymentMethodDetails);
  };

  const handleClosePaymentMethodDetail = () => {
    setOpenPaymentMethodDetail(false);
    setEditModePaymentMethodDetail(false);
  };

  const handleInputChangePaymentMethodDetail = (event) => {
    const { name, value } = event.target;
    setSelectedPaymentMethodDetail({
      ...selectedPaymentMethodDetail,
      [name]: value,
    });
  };

  const handleSelectChangePaymentMethodDetail = (event) => {
    const { name, value } = event.target;
    setSelectedPaymentMethodDetail({
      ...selectedPaymentMethodDetail,
      [name]: value,
    });
  };

  const handleSavePaymentMethodDetail = async () => {
    if (editModePaymentMethodDetail) {
      const updatedPaymentMethodDetails = paymentMethodDetails.map((type) =>
        type.id === selectedPaymentMethodDetail.id
          ? { ...selectedPaymentMethodDetail, fee: selectedFeeForPMD }
          : type
      );
      const updatePaymentMethodDetail = {
        id: selectedPaymentMethodDetail.uuid,
        data: {
          // method:"Forma PRUEBA",
          uuid_fee: selectedFeeForPMD.uuid,
          uuid_payment_method: selectedPaymentMethodForPMD.uuid,
          item_order: selectedPaymentMethodDetail.item_order,
        },
      };

      const updatedPaymentMethodDetail = await dispatch(
        updatePaymentMethodDetailById(updatePaymentMethodDetail)
      ).unwrap();

      setPaymentMethodDetails(updatedPaymentMethodDetails);
      handleClosePaymentMethodDetail();
    } else {
      try {
        const newPaymentMethodDetail = {
          uuid_plan: selectedPlan.uuid,
          method: "method",
          uuid_fee: selectedFeeForPMD.uuid,
          uuid_payment_method: selectedPaymentMethodForPMD.uuid,
          item_order: selectedPaymentMethodDetail.item_order,
        };
        const addedPaymentMethodDetail = await dispatch(
          addPaymentMethodDetail(newPaymentMethodDetail)
        ).unwrap();
        const addedPaymentMethodDetailWithFee = {
          ...newPaymentMethodDetail,
          fee: selectedFeeForPMD,
        };

        setSelectedPaymentMethodDetail({
          ...addedPaymentMethodDetailWithFee,
          id: paymentMethodDetails.length + 1,
        });

        setPaymentMethodDetails([
          ...paymentMethodDetails,
          addedPaymentMethodDetailWithFee,
        ]);
        handleClosePaymentMethodDetail();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const paymentMethodDetailDialogProps = {
    openPaymentMethodDetail,
    handleClosePaymentMethodDetail,
    editModePaymentMethodDetail,
    selectedPaymentMethodDetail,
    handleInputChangePaymentMethodDetail,
    handleSelectChangePaymentMethodDetail,
    handleSavePaymentMethodDetail,
    namePaymentMethodDetail,
  };
  //#endregion

  const planTabProps = {
    activeTab,
    setActiveTab,

    //Grupos
    getCurrentGroupPageItems,
    handleEditGroup,
    handleDeleteGroup,
    groupDialogProps,
    pageGroup,
    groups,
    setPageGroup,
    setSelectedGroup,
    setOpenGroup,
    setFilteredGroups,

    //Adicionales
    getCurrentAdditionalPageItems,
    handleEditAdditional,
    handleDeleteAdditional,
    additionalDialogProps,
    pageAdditional,
    additionals,
    setPageAdditional,
    setSelectedAdditional,
    setOpenAdditional,
    setFilteredAdditionals,

    //Coeficientes de puntaje
    getCurrentScoreCoefficentPageItems,
    handleEditScoreCoefficent,
    handleDeleteScoreCoefficent,
    scoreCoefficentDialogProps,
    pageScoreCoefficent,
    scoreCoefficents,
    setPageScoreCoefficent,
    setSelectedScoreCoefficent,
    setOpenScoreCoefficent,
    setFilteredScoreCoefficents,

    //Motivos de prdialogConstTypeeadjudicacion
    getCurrentPreAdjRPageItems,
    handleEditPreAdjR,
    handleDeletePreAdjR,
    preAdjRDialogProps,
    pagePreAdjR,
    preAdjRs,
    setPagePreAdjR,
    setSelectedPreAdjR,
    setOpenPreAdjR,
    setFilteredPreAdjRs,

    //Tipos de construccion
    getCurrentConstTypePageItems,
    handleEditConstType,
    handleDeleteConstType,
    constTypeDialogProps,
    pageConstType,
    constTypes,
    setPageConstType,
    setSelectedConstType,
    setOpenConstType,
    setFilteredConstTypes,

    //Valores PEI
    peiValueDialogProps,
    pagePeiValue,
    legalEntities,
    setPagePeiValue,
    openPeiValue,
    setOpenPeiValue,

    //Tipos de Cuotas
    handleEditFee,
    handleDeleteFee,
    feeDialogProps,
    pageFee,
    fees,
    setPageFee,
    setSelectedFee,
    setOpenFee,

    //Estructura de Cuotas
    handleEditFeeStructure,
    handleDeleteFeeStructure,
    feeStructureDialogProps,
    feeStructures,
    setSelectedFeeStructure,
    setOpenFeeStructure,
    selectedPlan,

    //Forma de Pagos
    handleEditPaymentMethod,
    handleDeletePaymentMethod,
    paymentMethodDialogProps,
    paymentMethods,
    setSelectedPaymentMethod,
    setOpenPaymentMethod,

    //Detalle de Forma de Pagos
    handleEditPaymentMethodDetail,
    handleDeletePaymentMethodDetail,
    paymentMethodDetailDialogProps,
    paymentMethodDetails,
    setSelectedPaymentMethodDetail,
    setOpenPaymentMethodDetail,
    // selectedPlan ya lo paso arriba
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={false}>
      <FormDialogTitle>
        {editMode ? "Editar plan" : "Agregar plan"}
      </FormDialogTitle>
      <SeparatorHrBox>
        <hr />
      </SeparatorHrBox>

      <DialogContent
        sx={{ mt: -2, display: "block", justifyContent: "center" }}
      >
        <Box
          style={{
            boxShadow: editMode ? "2px 2px 10px rgba(1,2,10,0.3)" : 0,
            padding: "25px 55px 25px 55px",
            borderRadius: 15,
          }}
        >
          <Box>
            <Box>
              <FormDialogSubtitle sx={{ fontSize: "25px" }}>
                {" "}
                Configuración del plan : {namePlan}{" "}
              </FormDialogSubtitle>
            </Box>
          </Box>
          <Box>
            <FormBoxContainer
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <FormBoxLeft>
                <TextField
                  margin="dense"
                  name="name"
                  label="Nombre"
                  type="text"
                  fullWidth
                  value={selectedPlan.name ? selectedPlan.name : ""}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  name="branch"
                  label="Sucursal"
                  type="text"
                  fullWidth
                  value={
                    selectedPlan.branch ? selectedPlan.branch.description : ""
                  }
                  disabled
                  onChange={handleInputChange}
                />
                {/* <SelectFormControl style={{ marginTop: 9 }}>
                  <Select
                    labelId="group_id-label"
                    name="group_id"
                    value={selectedPlan.group_id || SucursalOptions[0].value}
                    onChange={handleSelectChange}
                  >
                    {SucursalOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </SelectFormControl> */}
              </FormBoxLeft>
              <FormDivider orientation="vertical" sx={{ height: "145px" }} />
              <FormBoxLeft>
                <TextField
                  margin="dense"
                  name="effective_start_date"
                  label="Fecha Inic. Vig."
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  value={
                    selectedPlan.effective_start_date
                      ? new Date(selectedPlan.effective_start_date)
                          .toISOString()
                          .split("T")[0]
                      : null
                  }
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  name="finish_date"
                  label="Fecha Baja"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  disabled
                  onChange={handleInputChange}
                />
              </FormBoxLeft>
              <FormDivider orientation="vertical" sx={{ height: "145px" }} />

              <FormBoxRight>
                <TextField
                  margin="dense"
                  name="installments_number"
                  label="Cantidad de Cuotas"
                  type="number"
                  fullWidth
                  value={
                    selectedPlan.installments_number
                      ? selectedPlan.installments_number
                      : null
                  }
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  name="contracts_number"
                  label="Cantidad de Contrat."
                  type="number"
                  fullWidth
                  value={
                    selectedPlan.contracts_number
                      ? selectedPlan.contracts_number
                      : null
                  }
                  onChange={handleInputChange}
                />
              </FormBoxRight>
              <FormDivider orientation="vertical" sx={{ height: "145px" }} />
              <FormBoxRight>
                <TextField
                  margin="dense"
                  name="interest_rate"
                  label="Interes x Def.(%)"
                  type="number"
                  fullWidth
                  value={
                    selectedPlan.interest_rate
                      ? selectedPlan.interest_rate
                      : null
                  }
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  name="first_installment_amount"
                  label="1er Valor Cuota($)"
                  type="number"
                  fullWidth
                  value={
                    selectedPlan.first_installment_amount
                      ? selectedPlan.first_installment_amount
                      : null
                  }
                  onChange={handleInputChange}
                />
              </FormBoxRight>
            </FormBoxContainer>
          </Box>
        </Box>

        {editMode ? <PlanTab {...planTabProps} /> : <></>}
      </DialogContent>
      <Actions {...actionProps} />
    </Dialog>
  );
};

export default PlanDialog;
