import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getDistricts = createAsyncThunk("districts", async () => {
  const response = await apiClient.get("districts");
  return response.data;
});

export const getDistrictById = createAsyncThunk("districts/id", async ( id ) => {
  const response = await apiClient.get(`districts/${id}`);
  return response.data;
});

export const deleteDistrictById = createAsyncThunk("districts/delete", async ( id ) => {
  const response = await apiClient.delete(`districts/${id}`);
  return response.data;
});

export const addDistrict = createAsyncThunk("districts/add", async (district) => {
delete district.deleted_at;
delete district.updated_at;
delete district.created_at;
delete district.is_active;
delete district.modules;
  const response = await apiClient.post("districts", district);
  return response.data;
});

export const updateDistrict = createAsyncThunk("districts/update", async ( district ) => {
  delete district.deleted_at;
  delete district.updated_at;
  delete district.created_at;
  delete district.is_active;
  delete district.modules;
  
  const response = await apiClient.put(`districts/${district.uuid}`, district);
  return response.data;
});