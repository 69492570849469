import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getCountries = createAsyncThunk("countries", async () => {
  const response = await apiClient.get("countries");
  return response.data;
});

export const getCountryById = createAsyncThunk("countries/id", async ( id ) => {
  const response = await apiClient.get(`countries/${id}`);
  return response.data;
});

export const deleteCountryById = createAsyncThunk("countries/delete", async ( id ) => {
  const response = await apiClient.delete(`countries/${id}`);
  return response.data;
});

export const addCountry = createAsyncThunk("countries/add", async (country) => {
delete country.deleted_at;
delete country.updated_at;
delete country.created_at;
delete country.is_active;
delete country.modules;
  const response = await apiClient.post("countries", country);
  return response.data;
});

export const updateCountry = createAsyncThunk("countries/update", async ( country ) => {
  delete country.deleted_at;
  delete country.updated_at;
  delete country.created_at;
  delete country.is_active;
  delete country.modules;
  
  const response = await apiClient.put(`countries/${country.uuid}`, country);
  return response.data;
});