import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import {
  HeadTableCell,
  RowTableCell,
  EditButton,
} from "../../assets/styles/tables";
import { useDispatch } from "react-redux";
import { addLegalEntity } from "../../features/legalEntities/legalEntitySlice";
import EditIcon from "@mui/icons-material/Edit";


const PeiValueTab = (props) => {
  const dispatch = useDispatch();
  const { setSelectedPeiValue, setOpenPeiValue, legalEntities } = props;
  const handleEditDialogOpen = (entity) => {
    dispatch(addLegalEntity(entity));
    setOpenPeiValue(true);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <HeadTableCell sx={{ width: "80%", textAlign: "center" }}>
              Entidad Intermedia
            </HeadTableCell>
            <HeadTableCell sx={{ width: "20%", textAlign: "center" }}>
              Acciones
            </HeadTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {legalEntities.map((entity, key) => (
            <TableRow key={key}>
              <RowTableCell sx={{ textAlign: "center" }}>
                {entity.legal_business_name}
              </RowTableCell>
              <RowTableCell sx={{ textAlign: "center" }}>
                <EditButton
                  variant="contained"
                  onClick={() => handleEditDialogOpen(entity)}
                >
                  <IconButton size="small">
                    <EditIcon sx={{ color: "whitesmoke" }} />
                  </IconButton>
                </EditButton>
              </RowTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default PeiValueTab;
